import api from '@/services/api';
import {
  setLoadingStatus, setCollection, addItem, setItem,
} from '../mutations';
import { item } from '../getters';

const actions = {
  async index({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/company-quotas', { params });
      commit('setCollection', data);
    } catch (error) {
      throw new Error('Não foi possível carregar a lista de quotas.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async create({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post('v1/company-quotas', body);
      commit('addItem', data);
      return data;
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível salvar os dados da quota.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async edit({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.patch(`v1/company-quotas/${body.id}`, body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível editar os dados da quota.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async remove({ commit, rootState }, { id }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      await api.delete(`v1/company-quotas/${id}`, { params });
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível deletar.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setCollection,
  addItem,
  setItem,
  setAction(st, action) {
    st.action = action;
  },
  setSignerResume(st, signerResume) {
    st.signerResume = signerResume;
  },
};

// Agrupa as ações por id e calcula o valor total de cada, retorna array de array
function getArrayStocksOnlyInfo(stocksUniqueTypes, arrayStocks) {
  const resultArrays = [];
  stocksUniqueTypes.forEach((stockUnique) => {
    const filteredStocks = arrayStocks.reduce((arr, stock) => {
      if (stock.stock.type === stockUnique.type) {
        const { total } = stock;
        arr.push({
          stock_id: stock.stock_id,
          signer_id: stock.signer_id,
          name: stock.signer.type === 'PF' ? stock.signer.name : stock.signer.business_name,
          type: stock.stock.type,
          id: stock.id,
          date: stock.date,
          value: stockUnique.value, // valor da ação correspondente a data de pesquisa
          issuePrice: total * stockUnique.issue_price, // preço correspondente a data de pesquisa
          total,
        });
      }
      return arr;
    }, []);
    resultArrays.push(filteredStocks);
  });
  return resultArrays;
}
// Agrupa por acionista dentro de cada array, devolve array de array
function getArrayStockBySigner(arrays) {
  const finalResultArray = [];
  arrays.forEach((array) => {
    const result = {};
    array.forEach((stock) => {
      const {
        signer_id: signerId, total, value, stock_id: stockId, name, type, issuePrice, date,
      } = stock;
      if (!result[signerId]) {
        result[signerId] = stock;
      } else {
        result[signerId].signer_id = signerId;
        result[signerId].name = name;
        result[signerId].type = type;
        result[signerId].stock_id = stockId;
        result[signerId].total += total;
        result[signerId].value = value;
        result[signerId].issuePrice += issuePrice;
        result[signerId].date = date;
      }
    });
    finalResultArray.push(Object.values(result));
  });
  return finalResultArray;
}
// calcula a particiação de cada quotista, devolve array de array
function getArrayStockWithParticipation(arrays, arrayStocksRaw) {
  const stockToCalc = arrayStocksRaw.reduce((acc, stock) => acc + stock.total, 0);
  arrays.forEach((array) => {
    array.forEach((stock) => {
      stock.part = Number((stock.total / stockToCalc) * 100);
    });
  });
  return arrays;
}
// devolve o array espalhado de captable, o valor e a participação total do montante de quotistas
function calculateTotal(array) {
  let resultArray = [];
  array.forEach((arr) => resultArray.push(...arr));
  const totalQtd = resultArray.reduce((acc, stock) => acc + stock.total, 0);
  const totalPrice = resultArray.reduce((acc, stock) => stock.value, 0);
  const totalIssuePrice = resultArray.reduce((acc, stock) => acc + stock.issuePrice, 0);
  const totalPart = resultArray.reduce((acc, stock) => acc + stock.part, 0).toFixed(0);
  resultArray = resultArray.filter((cap) => (cap.total > 0 ? cap : 0));
  const total = {
    name: 'Total',
    type: ' ',
    total: totalQtd,
    value: totalPrice,
    issuePrice: totalIssuePrice,
    part: totalPart,
  };
  const captableExcelExport = resultArray.map((cap) => ({
    ...cap,
    part: cap.part.toString().replace('.', ','),
  }));
  return {
    captable: [...resultArray, total],
    captableExcelExport,
    totalQtd,
    totalPrice,
    totalPart,
  };
}

const getters = {
  items(st) {
    return st.items;
  },
  getCaptable(state, get, rootState, rootGetters) {
    const arrayStocksOnlyInfo = getArrayStocksOnlyInfo(
      rootGetters['stock/itemsCaptable'],
      get.items,
    );
    const arrayStockBySigner = getArrayStockBySigner(arrayStocksOnlyInfo);
    const arrayComplete = getArrayStockWithParticipation(
      arrayStockBySigner,
      rootGetters['stock/itemsCaptable'],
    );
    return calculateTotal(arrayComplete);
  },
  item,
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    items: [],
    item: {},
    action: null,
    signerResume: [],
  },
  getters,
  mutations,
  actions,
};
