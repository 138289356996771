import api from '@/services/api';
import {
  setLoadingStatus, setCollection, addItem, setItem,
} from '../mutations';
import { item } from '../getters';

const actions = {
  async index({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/books/sa/stock-registry', { params });
      commit('setCollection', data);
    } catch (error) {
      throw new Error('Não foi possível carregar a lista de ações.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async getSignerResume({ commit, rootState }, { id, bookId }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
      book_id: bookId,
    };
    try {
      const { data } = await api.get(`v1/books/sa/stock-registry/resume/${id}`, { params });
      commit('setSignerResume', data);
    } catch (error) {
      throw new Error('Não foi possível carregar a lista de ações.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async getSignersCaptableResume({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/books/sa/stock-registry/signers-captable-resume', { params });
      commit('setCollectionCaptableResume', data);
    } catch (error) {
      throw new Error('Não foi possível carregar a lista de ações.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async create({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post('v1/books/sa/stock-registry', body);
      commit('addItem', data);
      return data;
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível salvar os dados da ação.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async edit({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.patch(`v1/books/sa/stock-registry/${body.id}`, body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível editar os dados da ação.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async remove({ commit, rootState }, { id }) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      await api.delete(`v1/books/sa/stock-registry/${id}`, { params });
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível deletar.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setCollection,
  addItem,
  setItem,
  setAction(st, action) {
    st.action = action;
  },
  setSignerResume(st, signerResume) {
    st.signerResume = signerResume;
  },
  setCollectionCaptableResume(st, resume) {
    st.captableResume = resume;
  },
};

const getters = {
  items(st) {
    return st.items;
  },
  itemsPositive(st) {
    return st.items.map((stock) => ({
      ...stock,
      conver_convertidas: Math.abs(stock.conver_convertidas),
      trans_transferidas: Math.abs(stock.trans_transferidas),
      amort_quantidade: Math.abs(stock.amort_quantidade),
    }));
  },
  itemsByAction(st) {
    return st.items
      .filter((stock) => stock.action === st.action)
      .map((stock) => ({
        ...stock,
        conver_convertidas: Math.abs(stock.conver_convertidas),
        trans_transferidas: Math.abs(stock.trans_transferidas),
        amort_quantidade: Math.abs(stock.amort_quantidade),
      }));
  },
  getCaptable(st) {
    return st.captableResume;
  },
  item,
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    items: [],
    item: {},
    action: null,
    signerResume: [],
    captableResume: [],
  },
  getters,
  mutations,
  actions,
};
