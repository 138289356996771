import api from '@/services/api';
import { setLoadingStatus, setCollection, addItem } from '../mutations';
import { items } from '../getters';

const actions = {
  async index({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/stocks', { params });
      commit('setCollection', data);
    } catch (error) {
      throw new Error('Não foi possível carregar as ações.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async indexCaptable({ commit, rootState }, params = {}) {
    commit('setLoadingStatus', true);
    params.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.get('v1/stocks/captable', { params });
      commit('setCollectionCaptable', data);
    } catch (error) {
      throw new Error('Não foi possível carregar as ações.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async create({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.post('v1/stocks', body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível salvar os dados.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async edit({ commit, rootState }, body) {
    commit('setLoadingStatus', true);
    body.company_id = rootState.company.selectedCompany.id;
    try {
      const { data } = await api.patch(`v1/stocks/${body.id}`, body);
      commit('addItem', data);
    } catch (error) {
      if (error.name === 'ValidationException') throw error;
      throw new Error('Não foi possível editar os dados.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
  async remove({ commit, dispatch, rootState }, id) {
    commit('setLoadingStatus', true);
    const params = {
      company_id: rootState.company.selectedCompany.id,
    };
    try {
      await api.delete(`v1/stocks/${id}`, { params });
      await dispatch('index');
    } catch (error) {
      if (error.name === 'BadRequestException') throw new Error(error.message);
      throw new Error('Não foi possível deletar.');
    } finally {
      commit('setLoadingStatus', false);
    }
  },
};

const mutations = {
  setLoadingStatus,
  setCollection,
  addItem,
  setCollectionCaptable(state, itemsCaptable) {
    state.itemsCaptable = itemsCaptable;
  },
};

const getters = {
  items,
  itemsCaptable(st) {
    return st.itemsCaptable;
  },
  groupedStocks(st) {
    const grouped = st.items
      .toSorted((a, b) => new Date(a.issue_date) - new Date(b.issue_date))
      .reduce((acc, action) => {
        if (!acc[action.type + action.class]) {
          acc[action.type + action.class] = {
            ...action,
            stocks: [],
          };
        } else if (
          new Date(action.issue_date) - new Date(acc[action.type + action.class].issue_date)
        ) {
          acc[action.type + action.class].stocks.push({ ...acc[action.type + action.class] });
          acc[action.type + action.class] = {
            ...action,
            stocks: acc[action.type + action.class].stocks,
          };
        } else {
          acc[action.type + action.class].stocks.push(action);
        }
        return acc;
      }, {});

    return Object.values(grouped);
  },
  stockIds(st) {
    return st.items.map((stock) => stock.id);
  },
  stocksUniqueTypes(st) {
    return st.items
      .filter((v, i, a) => a.findIndex((v2) => v2.type === v.type) === i)
      .map((stock) => stock);
  },
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    items: [],
    itemsCaptable: [],
  },
  getters,
  mutations,
  actions,
};
